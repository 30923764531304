<!--
 * @Author: Han
 * @Date: 2021-11-09 15:50:10
 * @LastEditors: Han
 * @LastEditTime: 2021-12-29 18:02:04
 * @FilePath: \HanSharePage\src\components\HanHead.vue
-->
<template>
  <header>
    <div class="vvhan-head">
      <div class="vvhan-logo">
        <img src="@/assets/images/logo.png" /><span>Han</span>
      </div>
      <div class="vvhan-text">
        <span>Han-ShareWeb</span>
      </div>
    </div>
    <div class="vvhan-title">
      <h1>{{ $store.state.headTitle }}</h1>
    </div>
    <div class="vvhan-tips">
      <el-collapse v-model="ACTIVE">
        <el-collapse-item title="关于本站" name="hanTip">
          <div>Q:网站又没有病毒或者广告？🍓</div>
          <div>A:都是自己人，绿色健康无毒无广告。</div>
          <br />
          <div>Q:视频速度卡不卡？🍓</div>
          <div>A:全站切片，国内存储，CDN加速，速度秒加载。</div>
          <br />
          <div>Q:我想看的视频没有怎么办？🍓</div>
          <div>A:无论你是谁，直接找我，国内外任意视频，秒上线。</div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      ACTIVE: ["hanTip"],
      fullscreenLoading: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.ACTIVE = ["1"];
    }, 5666);
  },
};
</script>

<style>
.el-collapse {
  border: none;
}

.el-collapse-item__wrap {
  border-bottom: none;
}

.el-collapse-item__content {
  padding-bottom: 0;
  padding-top: 16px;
}

.el-collapse-item__header {
  line-height: normal !important;
  height: auto !important;
  border-bottom: none !important;
  color: #db7c22;
  font-weight: bolder;
  font-size: 14px;
  line-height: 20px;
}
</style>
<style lang="less" scoped>
header {
  padding-bottom: 28px;

  .vvhan-head {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
    height: 56px;
    width: 100%;

    .vvhan-logo {
      display: flex;
      align-items: center;

      img {
        width: 28px;
      }

      span {
        text-indent: 5px;
      }
    }

    .vvhan-text {
      span {
        line-height: 56px;
        text-align: right;
      }
    }
  }

  .vvhan-title {
    width: 100%;
    height: 70px;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    transition: all 0.5s ease 0s;

    &:hover {
      color: rgb(255, 255, 255);
      background-color: rgb(13, 14, 83);
      animation: 0.65s cubic-bezier(1, -1.91, 0, 2.79) 0s infinite normal both
        running uk-text-shadow-glitch;
    }

    h1 {
      font-size: 24px;
      font-weight: bold;
      line-height: 70px;
    }
  }

  .vvhan-tips {
    box-sizing: border-box;
    padding: 20px;
    margin-top: 16px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    color: #3b3b3b;
    font-weight: bolder;
    font-size: 14px;
    line-height: 20px;
  }
}

@keyframes uk-text-shadow-glitch {
  0% {
    text-shadow: none;
  }

  25% {
    text-shadow: -2px -2px 0 #ff0048, 2px 2px 0 #3234ff;
  }

  50% {
    text-shadow: 2px -2px 0 #ff0048, -2px 2px 0 #3234ff;
  }

  75% {
    text-shadow: -2px 2px 0 #ff0048, 2px -2px 0 #3234ff;
  }

  100% {
    text-shadow: 2px 2px 0 #ff0048, -2px -2px 0 #3234ff;
  }
}

@keyframes uk-flicker {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.6;
    transform: scale(0.8);
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>